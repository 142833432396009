import Vue from "vue";
import VueRouter from "vue-router";
import { storeToRefs } from "pinia";
import { useloggedUserInfo, moduleStore } from "../stores/UserInfo";
//import Callback from '../views/auth/Callback.vue'
import Unauthorized from "../views/auth/Unauthorized.vue";
import authservice from "../service/authservice";
import DataService from "../service/DataService";
Vue.use(VueRouter);
const routes = [
  {
    path: "/logout",
    name: "logout",
    component: Unauthorized,
  },
  {
    path: "/callback",
    name: "callback",
    //component: Callback
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: Unauthorized,
  },
  {
    path: "/Asset/GroupListing",
    name: "GroupList",
    component: () => import("../views/ItemGroupList/List.vue"),
    meta: {
      title: "Items Group List",
      breadcrumb: [{ name: "Products Group List" }],
    },
  },
  {
    path: "/AssetCatalog/Index",
    name: "ProductListing",
    component: () => import("../views/AssetCatalog/ProductListing.vue"),
    meta: { title: "Manage Product",
    breadcrumb: [{ name: "Product" }] },
  },

  {
    path: "/Asset/MyItems",
    name: "MyProducts",
    component: () => import("../views/MyProduct/List.vue"),
    meta: {
      title: "My Products",
      breadcrumb: [{ name: "My Product" }],
    },
  },
  {
    path: "/Asset/Index",
    name: "ProductType",
    component: () => import("../views/Asset/ProductType.vue"),
    meta: {
      title: "Product Type",
      breadcrumb: [{ name: "Product Type" }],
    },
  },
  {
    path: "/AssetAssignment/Index",
    name: "ProductAssignment",
    component: () => import("../views/ProductAssignment/List.vue"),
    meta: {
      title: "Product Assignment",
      breadcrumb: [{ name: "Product Assignment" }],
    },
  },
  {
    path: "/AssetAssignment/Index/:id",
    name: "ProductAssignment",
    component: () => import("../views/ProductAssignment/List.vue"),
    meta: {
      title: "Talygen-Product Assignment",
      breadcrumb: [{ name: "Product Assignment" }],
    },
  },
  {
    path: "/AssetAssignment/Add",
    name: "AddAssetAssignment",
    component: () => import("../views/ProductAssignment/Manage.vue"),
    meta: {
      title: "Assign Product",
      breadcrumb: [
        { name: "Product Assignment", link: "/AssetAssignment/Index" },
        { name: "Assign Product" },
      ],
    },
  },
  {
    path: "/AssetAssignment/Add/:reqForm",
    name: "AddAssetAssignment",
    component: () => import("../views/ProductAssignment/Manage.vue"),
    meta: {
      title: "Assign Product",
      breadcrumb: [
        { name: "Product Assignment", link: "/AssetAssignment/Index" },
        { name: "Assign Product" },
      ],
    },
  },

  {
    path: "/AssetAssignment/CheckOut",
    name: "AssetAssignmentCheckOut",
    component: () =>
      import("../views/ProductAssignment/AssetAssignmentCheckOut.vue"),
    meta: {
      title: "Check Out Request",
      breadcrumb: [
        { name: "Product", link: "/AssetCatalog/Index" },
        { name: "Check Out List", link: "/AssetRequisition/MyRequisitions" },
        { name: "Add Check Out" },
      ],
    },
  },

  {
    path: "/AssetAssignment/AddCheckOutRequest",
    name: "AssetAssignmentCheckOut",
    component: () =>
      import("../views/ProductAssignment/AssetAssignmentCheckOut.vue"),
    meta: {
      title: "Check out Request",
      checkPrivilege:false,
      breadcrumb: [
        { name: "Product", link: "/AssetCatalog/Index" },
        { name: "Check Out List", link: "/AssetRequisition/MyRequisitions" },
        { name: "Add Check Out" },
      ],
    },
  },

  {
    path: "/Asset/RFIDAssetListing",
    name: "RFIDAssetListing",
    component: () => import("../views/Asset/RFIDAssetListing.vue"),
    meta: {
      title: "RFID Asset Listing",
      breadcrumb: [{ name: "RFID Asset Listing" }],
    },
  },
  {
    path: "/Asset/PendingRequest",
    name: "PendingRequestList",
    component: () => import("@/views/Asset/PendingRequestList.vue"),
    meta: {
      title: "Pending Request",
      breadcrumb: [{ name: "Pending Requests" }],
    },
  },
  {
    path: "/AssetCatalog/Add",
    name: "Add Products",
    component: () => import("../views/AssetCatalog/ManageProducts.vue"),
    meta: {
      title: "ManageProducts",
      breadcrumb: [
        { name: "Product", link: "/AssetCatalog/index" },
        { name: "Add Product" },
      ],
    },
  },
  {
    path: "/AssetCatalog/Update",
    name: "Add Products",
    component: () => import("../views/AssetCatalog/ManageProducts.vue"),
    meta: {
      title: "ManageProducts",
      breadcrumb: [
        { name: "Product", link: "/AssetCatalog/index" },
        { name: "Update Product" },
      ],
    },
  },
  {
    path: "/AssetCatalog/Update/:id",
    name: "Update Products",
    component: () => import("../views/AssetCatalog/ManageProducts.vue"),
    meta: {
      title: "Update Product",
      breadcrumb: [
        { name: "Product", link: "/AssetCatalog/index" },
        { name: "Update Product" },
      ],
    },
  },
  {
    path: "/AssetCatalog/CustomEditProducts/:id",
    name: "CustomEditProducts",
    component: () => import("../views/AssetCatalog/CustomEditProducts"),
    meta: {
      title: "CreateEditProducts",
      breadcrumb: [
        { name: "Product", link: "/AssetCatalog/index" },
        { name: "Update Product" },
      ],
    },
  },

  {
    path: "/AssetRequisition/MyRequisitions",
    name: "ProductTypeRequest",
    component: () => import("../views/AssetRequisition/MyRequisitionsList.vue"),
    meta: {
      title: "Product Type Check Out Request",
      breadcrumb: [{ name: "Product",link:"/AssetCatalog/index" },
                    {name:"Check Out List"}
    ],
    },
  },

  {
    path: "/AssetRequisition/NewAssetRequisitionListing",
    name: "ProductTypeCheckOutRequest",
    component: () =>
      import("../views/AssetRequisition/NewAssetRequisitionListing.vue"),
    meta: {
      title: "New Product Type Requests",
      breadcrumb: [
        {
          name: "New Product Type Requests",
          link: "/AssetRequisition/NewAssetRequisitionListing",
        },
      ],
    },
  },

  {
    path: "/AssetRequisition/AddNewRequisition",
    name: "AddNewRequisition",
    component: () => import("../views/AssetRequisition/AddNewRequisition.vue"),
    meta: {
      title: "AddNewRequisition",
      breadcrumb: [
        {
          name: "New Product Type Requests",
          link: "/AssetRequisition/NewAssetRequisitionListing",
        },
        {
          name: "Request New Product",
        },
      ],
    },
  },

  {
    path: "/Asset/Scanneditems",
    name: "Scanneditems",
    component: () => import("../views/Asset/Scanneditems.vue"),
    meta: {
      title: "Scanned Items",
      breadcrumb: [
        { name: "Company Setup", link: "/Asset/Scanneditems" },
        { name: "Product Type" },
        { name: "Scanned Items" },
      ],
    },
  },
  // {
  //   path: "/AssetCatalog/AssetRelationshipListing",
  //   name: "AssetsRelationship",
  //   component: () => import("../views/AssetCatalog/AssetRelationshipListing.vue"),
  //   meta: {
  //     title: "Assets Relationship Listing",
  //     checkPrivilege:false,
  //     breadcrumb: [
  //       // { name: "Company Setup", link: "/Asset/Scanneditems" },
  //       // { name: "Product Type" },
  //       // { name: "Scanned Items" },
  //     ],
  //   },
  // },
  {
    path: "/AssetCatalog/ItemWriteOff",
    name: "ManageProducts",
    component: () => import("../views/AssetCatalog/ItemWriteOff.vue"),
    meta: {
      title: "Add Product Write-Off",
      breadcrumb: [
        { name: "Product"},
        { name: "Product Write-Off",
         link: "/AssetCatalog/ItemWriteOffList" },
        { name: "Add Product Write-Off" },
      ],
    },
  },
  {
    path: "/AssetCatalog/ItemWriteOffList",
    name: "ItemWriteOffList",
    component: () => import("../views/AssetCatalog/ItemWriteOffList.vue"),
    meta: {
      title: "Product Name",
      breadcrumb: [
        { name: "Product", link: "/AssetCatalog/index" },
        { name: "Product Write-Off" },
      ],
    },
  },
  {
    path: "/Asset/ReturnItems",
    name: "ReturnItems",
    component: () => import("../views/Asset/ReturnItems.vue"),
    meta: {
      title: "Return List",
      breadcrumb: [{ name: "Return List" }],
    },
  },
  // {
  //   path: "/Asset/AddItemsGroup",
  //   name: "AddItemsGroup",
  //   component: () => import("../views/ItemGroupList/Manage.vue"),
  //   meta: {
  //     title: "GroupListing",
  //     breadcrumb: [
  //       { name: "Products Group List", link: "/Asset/GroupListing" },
  //       { name: "Manage" },
  //     ],
  //   },
  // },
  {
    path: "/Asset/AddItemsGroup/:id",
    name: "ManageGroup",
    component: () => import("../views/ItemGroupList/Manage.vue"),
    meta: {
      title: "ManageGroup",
      breadcrumb: [
        { name: "Products Group List", link: "/Asset/GroupList" },
        { name: "Manage" },
      ],
      checkPrivilege:false,
   //   controllerName:"Asset",
    //  actionName:"AddItemsGroup",
    },
  },

  {
    path: "/Asset/ItemsScanSummary",
    name: "ItemsScanSummary",
    component: () => import("../views/Asset/ItemsScanSummary.vue"),
    meta: {
      title: "Items Scan Summary",
      breadcrumb: [
        //  { name: "Products", link: "/Asset/ItemsScanSummary" },
        { name: "Items Scan Summary" },
      ],
    },
  },
  {
    path: "/Asset/RfidMissingAssets",
    name: "RFID Missing Assets",
    component: () => import("../views/RFIDMissing/AssetMissingList.vue"),
    meta: {
      title: "RFID Missing Assets",
      breadcrumb: [
        //  { name: "Products", link: "/Asset/ItemsScanSummary" },
        { name: " RFID Missing Assets" },
      ],
    },
  },
  {
    path: "/Asset/RFIDMyDeviceList",
    name: "RFIDMyDeviceList",
    component: () => import("../views/Asset/RFIDMyDeviceList.vue"),
    meta: {
      title: "Scanned Items Status",
      breadcrumb: [{ name: "Scanned Products Status" }],
    },
  },
  {
    path: "/Asset/Depreciation",
    name: "Depreciation",
    component: () => import("../views/Asset/DepreciationList.vue"),
    meta: {
      title: "Depreciation List",
      breadcrumb: [{ name: "Depreciation" }],
    },
  },

  {
    path: "/AssetCatalog/RelatedInformation",
    name: "RelatedInformation",
    component: () => import("../views/AssetCatalog/RelatedInformation.vue"),
    meta: {
      title: "Product List",
      breadcrumb: [
        { name: "Product", link: "/AssetCatalog/index" },
        { name: "Product List" },
      ],
    },
  },
  {
    path: "/AssetCatalog/TransferItem",
    name: "Transferred Items",
    component: () => import("../views/AssetCatalog/TransferItem.vue"),
    meta: {
      title: "Product Transfer",
      breadcrumb: [
        { name: "Product", link: "/AssetCatalog/Index" },
        { name: "Product Transfer" },
      ],
    },
  },

  {
    path: "/AssetCatalog/ViewTransferedItem",
    name: "TransferredItemsList",
    component: () => import("../views/AssetCatalog/TransferedItemList.vue"),
    meta: {
      title: "Transferred List",
      breadcrumb: [
        { name: "Product ", link: "/AssetCatalog/Index" },
        { name: "Transferred Items" },
      ],
    },
  },
  {
    path: "/AssetCatalog/ViewInTransitItems",
    name: "ViewInTransitItems",
    component: () => import("../views/AssetCatalog/ViewInTransitItems.vue"),
    meta: {
      title: "In-Transit List",
      breadcrumb: [
        { name: "Product ", link: "/AssetCatalog/Index" },
        { name: "In-Transit Products" },
      ],
    },
  },
  {
    path: "/AssetCatalog/ImportAssetitem",
    name: "ImportAssetitem",
    component: () => import("../views/AssetCatalog/ImportAssetItem.vue"),
    meta: {
      title: "ImportAssetitem",
      breadcrumb: [
        { name: "Product ", link: "/AssetCatalog/Index" },
        { name: "Import Product" },
      ],
    },
  },

  {
    path: "/AssetAssignment/BarcodeSearch/:Id",
    name: "BarcodeSearch",
    component: () => import("../views/AssetAssignment/BarcodeSearch.vue"),
    meta: {
      title: "Product Information",
      breadcrumb: [
        { name: "Product Assignment", link: "/AssetAssignment/Index" },
        { name: "Item Information" },
      ],
    },
  },

  {
    path: "/AssetAssignment/BarcodeSearch",
    name: "BarcodeSearch",
    component: () => import("../views/AssetAssignment/BarcodeSearch.vue"),
    meta: {
      title: "Product Information",
      breadcrumb: [
        { name: "RelatedInformation", link: "/AssetCatalog/RelatedInformation" },
        { name: "ProductHistroy" },
      ],
    },
  },  

  {
    path: "/AssetCatalog/ScheduleUpdate",
    name: "ScheduleUpdate",
    component: () => import("../views/AssetCatalog/ScheduleUpdate.vue"),
    meta: {
      title: "Maintenance Schedule",
      breadcrumb: [{ name: "Maintenance Schedule" }],
    },
  },
  // {
  //   path: "/AssetCatalog/Calendar/:rid/:lid/:aid",
  //   name: "Calendar",
  //   component: () => import("../views/AssetCatalog/Calendar.vue"),
  //   meta: {
  //     title: "Calendar",
  //     checkPrivilege:false,
  //     breadcrumb: [
  //       { name: "Product", link: "/AssetCatalog/Index" },
  //       { name: "Calendar" },
  //     ],
  //   },
  // },
  {
    path: "/AssetCatalog/Calendar/:rid/:lid/:aid",
    name: "Calendar",
    component: () => import("../views/AssetCatalog/LoadAssetCalender.vue"),
    meta: {
      title: "Calendar",
      checkPrivilege:false,
      breadcrumb: [
        { name: "Product", link: "/AssetCatalog/Index" },
        { name: "Calendar" },
      ],
    },
  },
  {
    path: "/Asset/AssetGrouping",
    name: "AssetGrouping",
    component: () => import("../views/Asset/AssetGroupingList.vue"),
    meta: {
      title: "Product Container",
      breadcrumb: [
        {name: "Company Setup"},
        { name: "Product Container List",
       //link: "AssetCatalog/Index" 
      }],
    },
  },

  {
    path: "/Asset/Dashboard",
    name: "Dashboard",
    component: () => import("../views/Asset/Dashboard/Dashboard.vue"),
    meta: {
      title: "Product Dashboard",
      breadcrumb: [{ name: "Product Dashboard", 
      //link: "/Asset/Dashboard/Dashboard"
     }],
    },
  },

  {
    path: "/Asset/AdvancedSetup",
    name: "AdvancedSetup",
    component: () => import("../views/AdvancedSetup/AdvancedSetting.vue"),
    meta: {
      title: "Advanced Setting",      
      breadcrumb: [{ name: "Asset", link: "/AssetCatalog/Index" },{ name: "Advanced Setting" }],
    },
  },
  {
    path: "/AdvancedSetup/Asset",
    name: "AdvancedSetup",
    component: () => import("../views/AdvancedSetup/AdvancedSetting.vue"),
    meta: {
      title: "Advanced Setting",     
      breadcrumb: [{ name: "Asset", link: "/AssetCatalog/Index" },{ name: "Advanced Setting" }],
    },
  },
  {
    path: "/AssetReport/Index",
    name: "Report",
    component: () => import("../views/AssetReport/Index.vue"),
    meta: {
      title: "Product Report",
      breadcrumb: [{ name: "Product Report", link: "/AssetReport/Index" }],
    },    
  },
  {
    path: "/ProductType/Add",
    name: "ProductTypeAdd",

    component: () => import("../components/Modal/AddProductType.vue"),
    meta: {
      
      title: "Product Type Add",
      checkPrivilege:false
    },    
  },
  {
    path: "/Asset/AddFeature",
    name: "AddFeature",
    component: () => import("../views/AdvancedSetup/AddFeature"),
    meta: {
      title: "Add Feature",
    },
  },
  {
    path: "/Asset/ModuleFeature",
    name: "ModuleFeature",
    component: () => import("../views/AdvancedSetup/ModuleFeature"),
    meta: {
      title: "ModuleFeature",
      breadcrumb: [{ name: "Module Feature" }],
    },
  },
  {
    path: "/Asset/AddAssetGrouping",
    name: "AddProductContainer",
    component: () => import("../views/Asset/AddAssetGrouping.vue"),
    meta: {
      title: "Product Container",
      breadcrumb: [
        { name: "Company Setup" },
        { name: "Product Container List", link: "/Asset/AssetGrouping" },
        { name: "Manage" },
      ],
    },
  },
  {
    path: "/Asset/ImportItemType",
    name: "ImportProductType",
    component: () => import("../views/Asset/ImportItemType.vue"),
    meta: {
      title: "Import Product Type",
      breadcrumb: [
        { name: "Asset ", link: "/Asset/Index" },
        { name: "Import Product Type" },
      ],
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: () => import("../views/PageNotFound.vue"),
    meta: {
      title: "Page Not Found",
    },
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const DEFAULT_TITLE = "Home";
router.beforeResolve((to, from, next) => {
  document.title = "TALYGEN - " + (to.meta.title || DEFAULT_TITLE);
  document.getElementById("divProgress1").style.display = "none";
  document.getElementById("talygenfooter").style.display = "block";  
  
  if (
    to.path == "/callback" ||
    to.path == "/unauthorized" ||
    to.path == "/logoutcallback"
  ) {
    next();
  } else {
    if (to.path == "/logout") {
      authservice.logOut();
    } else {
      // if(to.path.indexOf('ContactImportDetails')>-1 && to.path.indexOf('CRM_ACCOUNTS')>-1){
      //   to.meta.breadcrumb=[{name:"Accounts", link:"/CRM/Accounts"},
      //   {name: "Import Account details"}]
      // }else if(to.path.indexOf('ContactImportDetails')>-1 && to.path.indexOf('CRM_CONTACTS')>-1){
      //     to.meta.breadcrumb=[{name:"Contact", link:"/CRM/ContactListing"},
      //     {name: "Import Contacts details"}]
      // }
      const ustore = useloggedUserInfo();
      let app = ustore.GetUserInfo;
      if (app) {
        let isCheckPrivilege = true;
        if(to.meta.hasOwnProperty('checkPrivilege'))
        {
            isCheckPrivilege = to.meta.checkPrivilege;
        }
        if(isCheckPrivilege) {
          let controllerName = "", actionName = "";
          if (to.meta.hasOwnProperty('actionName') && to.meta.hasOwnProperty('controllerName')) {
              controllerName = to.meta.controllerName;
              actionName = to.meta.actionName;
          }
          else{
              let pathArray = to.path.split("/");
              if(pathArray.length > 2){
                  controllerName = pathArray[1];
                  actionName = pathArray[2];
              } else{
                  controllerName = pathArray[0];
                  actionName = pathArray[1];
              }
          }
          if(controllerName != "" && actionName != "") {
              DataService.CheckPrivilege(`controller=${controllerName}&action=${actionName}`).then((value) => {
                  if (value.data)
                      next()
                  else
                      //common.methods.ShowAlert('Sorry, You are not authorized to access this section.', "warning", true, "Alert");
                      next('/unauthorized');
                  },
                  (error) => {
                      console.log(error);
                  }
              );
          } else {
              next()
          }
        } else {
          next();
        }
      } else {
        authservice.authenticate(to.path).then(() => {
          console.log("authenticating a protected url:" + to.path);
          next();
        });
      }
    }
  }
});
export default router;
