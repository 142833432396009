import http from "../http-common";
class DataService {
  GetContextMenu(params) {
    return http.instanceCommon.get("/Common/GetContextMenu?" + params);
  }
  GetLanguageList() {
    return http.instanceCommon.get("/Common/GetLanguageList");
  }
  GetFullMenu() {
    return http.instanceCommon.get("/Common/GetFullMenu");
  }
  async GetConfigurationValueForCompanyByKey(param) {
    return await http.instanceCommon.get(
      "/Common/GetConfigurationValueForCompanyByKey?" + param
    );
  }
  async GetHideContextMenuConfig() {
    return await http.instanceCommon.get("/Common/GetHideContextMenuConfig");
  }
  async SaveHideContextMenuConfig(param) {
    return await http.instanceCommon.post(
      "/Common/SaveHideContextMenuConfig?" + param
    );
  }
  async GetModuleMasterIdByModuleName(params) {
    return await http.instanceCommon.get(
      "/Common/GetModuleMasterIdByModuleName?" + params
    );
  }
  SaveTag(params) {
    return http.instance.post("/SaveTag", { postString: params });
  }
  // GetTagsByModuleId(params){
  //   return http.instanceCommon.get("/Tag/GetTagsByModuleId?"+params);
  // }
  GetFavDashboard() {
    return http.instanceCommon.get("/Common/GetFavDashboard");
  }
  GetItemGroupList(param) {
    return http.instance.get("/GetItemGroupList?" + param);
  }
  MyItemListing(param) {
    return http.instance.get("/GetMyItems?" + param);
  }
  AssetCatalogueListing(params) {
    return http.instance.get("/AssetCatalogueListing?" , {params} );
  }
  async GetDeviceList() {
    return await http.instance.get("/GetDeviceList");
  }
  async GetNotficationUsers(param) {
    return await http.instance.get("/GetNotficationUsers?" + param);
  }
  AddGroup(params) {
    return http.instance.post("/AddItemsGroup", { postString: params });
  }
  AddAssetWizard(params) {
    return http.instance.post("/AddAssetsWizard/", params);
  }
  AddProductType(params) {
    return http.instance.post("/AddProductType/", params);
  }
  async GetItemsGroupById(param) {
    return await http.instance.get("/GetItemsGroupById?" + param);
  }
  GetCountry(param) {
    return http.instance.get("/GetCountry?" + param);
  }
  GetStateListwithIDByCountryId(param) {
    return http.instance.get("/GetStateListwithIDByCountryId?" + param);
  }
  GetAssetCatalogueType(param) {
    return http.instance.get("/GetAssetCatalogueType?" + param);
  }
  async GetLocationByCompanyId(param) {
    return await http.instance.get("/GetLocationByCompanyId?" + param);
  }
  async GetTagsByModuleId(param) {
    return await http.instance.get("/GetTagsByModuleId?" + param);
  }
  LocationExist(param) {
    return http.instance.post("/LocationExist?" + param);
  }
  SaveUpdateLocation(params) {
    return http.instance.post("/SaveUpdateLocation", { postString: params });
  }
  GetStatusList(param) {
    return http.instance.get("/GetStatusList?" + param);
  }
  GetRFIDListing(params) {
    return http.instance.get("/GetRFIDListing?" + params);
  }
  DeleteGroupList(params) {
    return http.instance.post("/DeleteItemsGroup", { postString: params });
  }
  CommonUpdateStatus(params) {
    return http.instanceCommon.post("/Common/CommonUpdateStatus", {
      postString: params,
    });
  }
  statusdropdown(params) {
    return http.instanceCommon.get("/Common/GetStatusDDLData?" + params);
  }
  async GetDepartment(params) {
    return await http.instance.get(
      "/AssetAssignment/GetDepartmentListbyCompany?" + params
    );
  }
  async GetCompanyUsers(params) {
    return await http.instance.get(
      "/AssetAssignment/GetCompanyUsers?" + params
    );
  }

  async GetLocationByuserId(params) {
    return await http.instance.get(
      "/AssetAssignment/GetLocationByuserId?" + params
    );
  }

  async GetPendingRequsitionDetails(params) {
    //debugger
    return await http.instance.get(
      "/GetPendingRequsitionDetails?" + params
    );
  }

  async AssignGetAssetType(params) {   
    return await http.instance.get("/AssetAssignment/GetAssetType?" + params);
  }
  async GetCompanyAssestCatalogs(params) {
    return await http.instance.get(
      "/AssetAssignment/GetCompanyAssestCatalogsddl?" + params
    );
  }

  async AssetAssignmentListing(params) {
    return await http.instance.get(
      "/AssetAssignment/AssetAssignmentListing?" , { params } 
    );
  }
  CheckPrivilege(params) {
    return http.instanceCommon.get("/Common/HasPermission?" + params);
  }
  PendingRequestList(params) {
    return http.instance.get("/PendingRequestList?", { params });
  }
  GetAssetReplacementList(param) {
    return http.instance.get("/GetAssetReplacementList?" + param);
  }
  GetMyRequisitions(params) {
    return http.instance.get("/GetMyRequisitions?" + params);
  }
  async GetAssetType() {    
    return await http.instance.get("/AssetAssignment/GetAssetType");
  }
  async GetAssetData() {
    return await http.instance.get("/GetAssetType");
  }
  async GetUniqueNamesByCatalogId(param) {
    return await http.instance.get("/GetUniqueNamesByCatalogId?" + param);
  }
  async GetUniqueAssetItems(param) {
    return await http.instance.get(
      "/AssetAssignment/GetUniqueAssetItems?" + param
    );
  }
  async GetAssetCatalogWithContainer(param) {
    return await http.instance.get("/GetCompanyAssestCatalogs?" + param);
  }

  async GetCompanyAssestCatalogsDetalis(param) {
    return await http.instance.get("/AssetAssignment/GetCompanyAssestCatalogs?" + param);
  }
  async IsProductTransferable(param) {
    return await http.instance.get("/AssetAssignment/IsProductTransferable?" + param);
  }
  async GetCompanyAssestCatalogsAddToList(param) {
    return await http.instance.get("/AssetAssignment/GetCompanyAssestCatalogsAddToList?" + param);
  }
  GetApproveUnApprovelist(param) {
    return http.instance.get("/GetApproveUnApprovelist?" + param);
  }
  GetAssetReturnRequest(param) {
    return http.instance.get("/GetAssetReturnRequestList?" + param);
  }
  async GetAssetAvailability(param) {
    return await http.instance.get("/GetAssetAvailability?" + param);
  }
  ShowDeployItems(param) {
    return http.instance.get("/ShowDeployItems?" + param);
  }
  ShowRelatedInformationListing(params) {
    return http.instance.get("/ShowRelatedInformationListing?", { params });
  }
  async GetAssetItemStatusList() {
    return await http.instance.get("/GetAssetItemStatusList");
  }
  ViewFiles(param) {
    return http.instance.get("/ViewFiles?" + param);
  }
  ItemWriteOffList(params) {
    return http.instance.get("/ItemWriteOffList?" + params);
  }
  UpdateRequisitionStatus(params) {
    return http.instance.post("/UpdateRequisitionStatus", params);
  }
  SaveReplacementRequestStatus(params) {
    return http.instance.post("/AcceptRejectReplacementRequest", params);
  }
  SaveRepairRequest(param) {
    return http.instance.post("/SaveRepairRequest/", { poststring: param });
  }

  GetById(param) {
    return http.instance.get("/GetById?" + param);
  }
  async GetRFIDItemsToReturn(param) {
    return await http.instance.get("/GetRFIDItemsToReturn?" + param);
  }

  GetScannedItemsList(param) {
    return http.instance.get("/GetScannedItemsListList?" + param);
  }

  async GetAssignmentDetailByID(param) {
    return await http.instance.get(
      "/AssetAssignment/GetAssignmentDetailByID?" + param
    );
  }
  async BulkReturnItem(params) {
    return await http.instance.post("/AssetAssignment/BulkReturnItem/", {
      postString: params,
    });
  }
  ShowGetScannedItemsList(param) {
    return http.instance.get("/GetScannedItemsList?" + param);
  }
  GetAssetTypeCustom(param) {
    return http.instance.get("/GetAssetTypeCustom?" + param);
  }
  GetBarcodetype() {
    return http.instance.get("/GetBarcodetype");
  }
  async GetAssetReturnComment(param) {
    return await http.instance.get("/AssetAssignment/GetShowComment?" + param);
  }
  async GetCheckOut() {
    return await http.instance.get("/AssetAssignment/CheckOut");
  }

  GetAssetTypeDropDown() {
    return http.instance.get("/GetAssetType");
  }
  SetReturnedStaus(params) {
    return http.instance.post("/SetReturnedStaus", params);
  }
  
  ApprovedReject(params) {
    return http.instance.post("/UpdateReturnRequestApproveReject/" ,   { postString: params } );
  }
  SaveAssetCheckout(params) {
    return http.instance.post("/SaveAssetCheckout/", { postString: params });
  }
  GetRFIDGroupItemList(param) {
    return http.instance.get("/GetRFIDGroupItemList?" + param);
  }
  GetRFIDMissingAssets(param) {
    return http.instance.get("/GetRFIDMissingAssets?" + param);
  }
  AddRejectStatus(params) {
    return http.instance.post("/UpdateReturnRequestStatus?" , { postString: params });
  }
  GetDepreciationList(param) {
    return http.instance.get("/GetDepreciationList?" + param);
  }
  BulkRequestForReturn(param) {
    return http.instance.post("/AssetAssignment/BulkRequestForReturn?" + param);
  }

  GetCompanyClients(param) {
    return http.instance.get("/AssetAssignment/GetCompanyClients?" + param);
  }
  SaveGroupAssign(params) {
    return http.instance.post("/AssetAssignment/SaveGroupAssign/", params);
  }
  // GetCompanyClients(param) {
  //   return http.instance.get("/AssetAssignment/GetCompanyClients?" + param);
  // }
  GetItemHistory(param) {
    return http.instance.get("/GetItemHistory?" + param);
  }
  NewAssetRequisitionListing(params) {
    return http.instance.get("/NewAssetRequisitionListing?" + params);
  }
  UpdateRequisition(params) {
    return http.instance.get("/UpdateRequisition?" + params);
  }
  AddUpdateNewRequisition(params) {
    return http.instance.post("/AddUpdateNewRequisition?", params);
  }

  GetRelatedInfo(param) {
    return http.instance.get("/GetRelatedInformationById?" + param);
  }

  async GetEmployeeAutoComplete(param) {
    return await http.instance.get(
      "/AssetAssignment/GetEmployeeAutoComplete?" + param
    );
  }
  async ItemSharing(param) {
    return await http.instance.post("/AssetAssignment/ItemSharing?" + param);
  }

  async UpdateAssetAssignmentStatus(params) {
    return await http.instance.post(
      "/AssetAssignment/UpdateAssetAssignmentStatus?",
      {
        postString: params,
      }
    );
  }
  async EnableAssetManagement(param) {
    return await http.instance.get("/EnableAssetManagement?" + param);
  }
  SaveRequestForReturn(params) {
    return http.instance.post("/AssetAssignment/SaveRequetForReturn?", {
      postString: params,
    });
  }
  GetTransferedItemsList(param) {
    return http.instance.get("/GetTransferedItemsList?" + param);
  }
  GetViewCommentData(parm) {
    return http.instance.get("/ViewInTransititemsComment?" + parm);
  }
  ResolveDisputeProduct(params) {
    return http.instance.post("/ResolveDisputedProductSave?", {
      postString: params,
    });
  }

  AddSaveAssignGroup(params) {
    return http.instance.post("/SaveAssignGroup?" + params);
  }

  deleteRFID(params) {
    return http.instance.post("/DeleteRFID/", { postString: params });
  }

  GetScannedGroupName() {
    return http.instance.get("/GetScannedGroupName/");
  }

  GetTransitItemsList(param) {
    return http.instance.get("/GetTransitItemsList?" + param);
  }
  getFormLayout(params) {
    return http.instanceCommon.get("/FormLayout?" + params);
  }
  async GetViewList(params) {
    return await http.instanceCommon.get("/FormLayout/GetViewList?" + params);
  }
  async ApplyView(params) {
    return await http.instanceCommon.post("/FormLayout/ApplyView", { postString: params });
  }
  async ClearView(params) {
      return await http.instanceCommon.post("/FormLayout/ClearView", { postString: params });
  }
  async ViewListUsers(params) {
      return await http.instanceCommon.get("/FormLayout/ViewListUsers?" + params);
  }
  async ViewListFields(params) {
      return await http.instanceCommon.get("/FormLayout/ViewListFields?" + params);
  }
  async GetViewById(params) {
      return await http.instanceCommon.get("/FormLayout/GetViewById?" + params);
  }
  async SaveView(params) {

    return await http.instanceCommon.post("/FormLayout/SaveView", { postString: params });
  }

  async SearchCriteriaListing(params) {
    return await http.instanceCommon.get("/Common/SearchCriteriaListing?" + params);
  }
  async GetFieldName(params) {

      return await http.instanceCommon.get("/Common/GetFieldName?" + params);
  }
  // async SaveView(params) {

  //     return await http.instanceCommon.post("/FormLayout/SaveView", { postString: params });
  // }
  async GetOperator(params) {
      return await http.instanceCommon.get("/Common/GetOperator?" + params);
  }
  SearchCriteriaByIdView(params) {

      return http.instanceCommon.get("/Common/ViewFlterById?" + params);
  }
  async SearchCriteriaById(params) {

      return await http.instanceCommon.get("/Common/SearchCriteriaByIdforUpdateDelate?" + params);
  }
  async SaveFilter(params) {
      return await http.instanceCommon.post("/Common/SaveFilter", { postString: params })
  }
  SaveMultipleInTransitItems(params) {
    return http.instance.post("/SaveMultipleInTransitItems", params);
  }

  DownloadAllBarcode(param) {
    return http.instance.get("/DownloadAllBarcode?" + param);
  }
  SaveRelatedInfo(params) {
    return http.instance.post("/SaveRelatedInfo/", params);
  }

  UpdateInTransitItems(parms) {
    return http.instance.get("/UpdateInTransitItems?" + parms);
  }
  // SaveRelatedInfo(params) {
  //   return http.instance.post("/SaveRelatedInfo/", { postString: params });
  // }
  SaveDeploy(params) {
    return http.instance.post("/SaveDeployItems", params);
  }
  UpdateBarcode(param) {
    return http.instance.get("/UpdateBarcode?" + param);
  }
  // GetAssetItemStatusList(params) {
  //   return http.instance.get("/GetAssetItemStatusList?" + params);
  // }
  AssetReplacement(params) {
    return http.instance.post("/AssetReplacement/", { postString: params });
  }
  AssetReturn(params) {
    return http.instance.post("/AssetReturn/", { postString: params });
  }
  AssetRequest(params) {
    return http.instance.post("/AssetAssetRequest/", { postString: params });
  }
  GetAvailableQty(params) {
    return http.instance.get("/GetAvailableQty?" + params);
  }
  async UpdateQuantityOnLocation(params) {
    return await http.instance.post("/UpdateQuantityOnLocation/", params);
  }
  GetCatalogInfoByBarcode(params) {
    return http.instance.get("/GetCatalogInfoByBarcode?" + params);
  }
  RequsitionPendingList(params) {
    return http.instance.get("/RequsitionPendingList/", {
      params,
    });
  }
  // getFormLayout(params) {
  //   return http.instanceCommon.get("/FormLayout?" + params);
  // }
  GetMultipleInTransitItems(param) {
    return http.instance.get("/GetMultipleInTransitItems?" + param);
  }
  // DownloadAllBarcode(param) {
  //   return http.instance.get("/DownloadAllBarcode?" + param);
  // }
  // SaveRelatedInfo(params) {
  //   return http.instance.post("/SaveRelatedInfo/", params);
  // }
  GetLocationWiseUsers(param) {
    return http.instance.get("/GetLocationWiseUsers?" + param);
  }
  GetCompanyAssestCatalog(param) {
    return http.instance.get("/GetAssestCatalog?" + param);
  }
  AssetTransferToEmployee(params) {
    return http.instance.post("/AssetTransferToEmployee/", {
      postString: params,
    });
  }
  SaveIsAccept(params) {
    return http.instance.post("/SaveIsAccept/", params);
  }
  AssetSelfCheckOut(params) {
    return http.instance.post("/AssetSelfCheckOut/", { postString: params });
  }
  GetTermsConditionsFilePath(params) {
    return http.instance.get("/GetTermsConditionsFilePath?" + params);
  }
  SaveTermsConditions(params) {
    return http.instance.post("/SaveTermsConditions/", { postString: params });
  }
  Downloadfile(params) {
    return http.instance.get("/DownloadFile?" + params);
  }
  DownloadAttachment(params) {
    return http.instance.get("/DownloadAttachment?" + params);
  }
  DisputedProductSave(params) {
    return http.instance.post("/DisputedProductSave", params);
  }
  UserSelfCheckout(params) {
    return http.instance.get("/GetConfigurationValueByKey?" + params);
  }
  GetDeployItems(params) {
    return http.instance.get("/GetDeployItems?" + params);
  }
  GetRelatedProduct(params) {
    return http.instance.get("/GetRelatedProduct?" + params);
  }
  CloneProductSave(params) {
    return http.instance.post("/CloneProductSave/", {
      cloneProducttModel: params,
    });
  }
  Delete(param) {
    return http.instance.get("/Delete?" + param);
  }
  async GetModuleByName(params) {
    return await http.instance.get("/GetModuleByName?" + params);
  }
  async GetKeys(params) {
    return await http.instance.get("/GetKeys?" + params);
  }
  GetRelatedAssets(params) {
    return http.instance.get("/GetRelatedAssets?" + params);
  }
  UpdateAuditHistory(params) {
    return http.instance.post("/UpdateAuditHistory", { postString: params });
  }
  GetAuditHistory(params) {
    return http.instance.get("/GetAuditHistoryList?" + params);
  }
  PostDeal(params) {
    return http.instance.post("/SaveProduct", { postString: params });
  }
  UpdateWriteOffSave(params) {
    return http.instance.post("/UpdateWriteOff", { poststring: params });
  }
  AttachViewFiles(param) {
    return http.instance.get("/ViewFiles?" + param);
  }
  UpdataGroupListStatus(param) {
    return http.instance.get("/UpdataGroupListStatus?" + param);
  }
  GetListScheduleUpdate(param) {
    return http.instance.get("/GetListScheduleUpdate?" + param);
  }
  DeleteFile(param) {
    return http.instance.get("/DeleteFile?" + param);
  }
  GetAssetTagsById(param) {
    return http.instance.get("/GetAssetTagsById?" + param);
  }
  LoadAssetCalendar(param) {
    return http.instance.get("/LoadAssetCalendar?" + param);
  }
  UpdateCatalog(params) {
    return http.instance.post("/UpdateCatalog/", params);
  }
  DownloadFiles(params) {
    return http.instance.get("/DownloadFiles?" + params);
  }

  GetConfigurationData(params) {
    return http.instanceCommon.get("/Common/GetModuleKeys?" + params);
  }
  GetBlobUrl(params) {
    return http.instanceCommon.get("/Common/GetBlobUrl?" + params);
  }
  GetTimeZone() {
    return http.instanceCommon.get("Wizard/GetTimeZoneList");
  }
  GetCurrency() {
    return http.instanceCommon.get("/Common/GetCurrency");
  }
  ManageAssetSettingData(params) {
    return http.instance.post("/ManageAssetSettingData", params);
  }
  GetAssetGrouping(params) {
    return http.instance.get("AssetGrouping?" + params);
  }
  GetDashBoard(params) {
    return http.instance.get("/Dashboard?" + params);
  }
  LocalAssetReportTypeParam(params) {
    return http.instance.get("/AllReportTypeAssetByReportTypeParam?" + params);
  }
  MultiReportTypeAsset(params) {
    return http.instance.get("/MultiReportTypeAsset?" + params);
  }

  GetModuleList(params) {
    return http.instanceCommon.get("/Common/GetModuleList?" + params);
  }

  UpdateContainerStatus(params) {
    return http.instance.get("UpdateContainerStatus?" + params);
  }
  async SaveAssetRelationship(params) {
    return await http.instance.post("/AssetAssignment/SaveAssetRelationship", {
      poststring: params,
    });
  }
  UploadFile2(params) {
    return http.instance.post("/UploadFile2", params);
  }
  ManageDocUserStorageSettingData(params) {
    return http.instance.post("/ManageDocUserStorageSettingData", params);
  }
  GetConfigDataValue(params) {
    return http.instance.get("/GetConfigDataValue?" + params);
  }

  ReportsRemove(params) {
    return http.instance.get("/ReportsRemove?" + params);
  }

  GetPrivilegeListForEnableDisable(params) {
    return http.instanceCommon.get(
      "/Common/GetPrivilegeListForEnableDisable?" + params
    );
  }

  async GetEnableDisableSectionsListing(params) {
    return await http.instanceCommon.get(
      "/AdvanceFeatur/GetEnableDisableSectionsListing?" + params
    );
  }

  async SaveEnableDisableSections(params) {
    return await http.instanceCommon.post(
      "/AdvanceFeatur/SaveEnableDisableSections?" + params
    );
  }

  async GetEnableDisableFeature(params) {
    return await http.instanceCommon.get(
      "/AdvanceFeatur/GetEnableDisableFeature?" + params
    );
  }

  SaveEnableDisableFeature(params) {
    return http.instanceCommon.post(
      "/AdvanceFeatur/SaveEnableDisableFeature",
      params
    );
  }

  async GetReports(params) {
    return await http.instance.get("/GetReports?" + params);
  }
  async GetDashboardData(params) {
    return await http.instance.get(
      "/AllReportTypeAssetByReportTypeParamNew?" + params
    );
  }

  widgetSave(params) {
    return http.instance.post("/ReportsSave?" + params);
  }
  async GetCrmVendor() {
    return await http.instance.get("/GetCrmVendor");
  }

  GetStartEndDate_New(param) {
    return http.instance.post("/GetStartEndDate_New?" + param);
  }

  UpdateStatus12(params) {
    return http.instanceCommon.post("/Common/CommonUpdateStatus", {
      postString: params,
    });
  }
  async GetAssetContainer() {
    return await http.instance.get("GetAssetContainerType?");
  }
  GetCompartmentName(parms) {
    return http.instance.get("GetCompartmentName?" + parms);
  }
  GetItemNameDropdown(params) {
    return http.instance.get("/BindItemNamesLocationWise?" + params);
  }
  GetCompanyAssestCatalogsDDL(parms) {
    return http.instance.get("/GetCompanyAssestCatalogsDDL?" + parms);
  }
  async AddAssetGrouping(params) {
    return await http.instance.post("/AddAssetGrouping", {
      poststring: params,
    });
  }
  UpdateActiveInactiveAddGroup(params) {
    return http.instance.get("/UpdateContainerStatus?" + params);
  }
  DeleteItemsGroup(params) {
    return http.instance.post("/DeleteItemsGroup", { poststring: params });
  }

  DeleteViewAttachmentFile(params) {
    return http.instance.get("/DeleteViewAttachmentFile?" + params);
  }
  UpdateCatalogStatus(params) {
    return http.instance.get("/UpdateCatalogStatus?" + params);
  }
  UpdateEnableSection(params) {
    return http.instanceCommon.post(
      "/AdvanceFeatur/UpdateEnableSection",
      params
    );
  }
  UploadNewAssetTypeQuoteFiles(params) {
    return http.instance.post("/UploadNewAssetTypeQuoteFiles?", params);
  }
  GetCountryDDL(params) {
    return http.instanceCommon.get("/Common/GetDDLData?" + params);
  }
  async GetIssuedUser() {
    return http.instance.get("/LoadIssuedUser?");
  }
  ReportMain(params) {    
    return http.instanceCommon.post("/Report/ReportMain", { postString: params });
  }

  GetRelatedCatalogues(param) {
    return http.instance.get("/GetRelatedCatalogues?" + param);
  }

  async GetUserStatusitemlog() {
    return http.instance.get("/GetAllAssetOperationCodes?");
  }
  Addvendor(params) {
    return http.instance.post("/AddVendorModal", { postString: params });
  }

  async GetEnableDisableGroupCode() {
    return await http.instanceCommon.get(
      "/AdvanceFeatur/GetEnableDisableGroupCode/"
    );
  }
  SaveGroupRequisition(params) {
    return http.instance.post("/SaveGroupRequisition", { postString: params });
  }
  ImportProductSamplePost(param) {
    return http.instance.post("/ImportProductSamplePost/", param);
  }
  BulkUpload(params){
    return http.instance.get("/BulkUpload?"+ params);
  }
  GetScanneditemsData(params) {
    return http.instance.get("/RFIDMyDeviceList?" + params);
  }
  Index(params) {    
    return http.instance.get("/AssetListing?" + params);
  }
  UpdateStatus(params) {
    return http.instance.get("/UpdateStatus?" + params);
  }
  DeleteProductType(params) {
    return http.instance.get("/DeleteProductType?" + params);
  }

  UpdateRequisitionStatusCheckout(params){
    return http.instance.get("/UpdateRequisitionStatusCheckout?" + params);
  }
  AssetGetById(param) {
    return http.instance.get("/AssetGetById?" + param);
  }
  GetDepreciationRuleList(){
    return http.instance.get("/GetDepreciationRuleList?");

  }
  BulkUploadPost(params){
    return http.instance.post("/BulkUploadPost?", {
      poststring: params,
    });
  }
  GetUserType() {
    return http.instance.get("/GetUserType/");
  }
  BulkUploadProducytType(params){
    return http.instance.get("/BulkUploadProducytType?"+ params);
  }
  ProductTypeBulkUploadPost(params){
    return http.instance.post("/ProductTypeBulkUploadPost?",{
      poststring: params,
    });
  }
  NewAssetRequisitionDelete(params) {
    
    return http.instance.post("/NewAssetRequisitionDelete", { postString: params });
  }  
  ViewRequisitionDetail(params) {    
    return http.instance.get("/ViewRequisitionDetail?"+  params);
  }
  async GetLocationByBarcode(param) {
    return await http.instance.get(
      "/AssetAssignment/GetLocationByBarcode?" + param
    );
  }
  async BindItemLocationWise(params) {
    return await http.instance.get("/BindItemLocationWise?" + params);
  }

  async  GetUniqueAssetItemsExceptItemName(params){    
   return  await http.instance.get("/AssetAssignment/GetUniqueAssetItemsExceptItemName?"+params);
  }
  SaveBookMark(params){
    return http.instanceCommon.post("/Common/SaveBookMark", { postString: params });
  }
  UpdateAssetRequisitionQuoteStatus(params){
    return http.instance.post("/UpdateAssetRequisitionQuoteStatus", { postString: params });
  }
  async GetDynamicForm(params) {
    return await http.instanceCommon.get("/FormLayout/GetForm?" + params);
  }  
  async SaveDynamicForm(params) {
    return http.instanceCommon.post("/FormLayout/SaveDynamicForm", { postString: params })
  }  
  async CommonCommonDelete(params) {
    return await http.instanceCommon.post("/Common/CommonDelete", { postString: params });
  }
  async DeleteData(params) {
      return await http.instanceCommon.get("/Common/Delete?" + params);
  }
  IsDuplicateAssetCatalog(params) {
        return http.instance.post("/IsDuplicateAssetCatalog?", { postString: params });
  }
  GetItems(params) {
    return http.instance.get("/GetItems?"+ params);
  }
  GetUniqueNamesForRFID(params) {
    return http.instance.get("/GetUniqueNamesForRFID?"+ params);
  }
  UpdateItemsForRFIDTag(params) {    
    return http.instance.post("/PairEPCTagWithItem?", { postString: params });
  }
  GetAssetRelationListing(params) {
    return http.instance.get("/GetAssetRelationListing?" , { params });
  }
  async GetProgresSetupStatusDetail(params) { 
    return await http.instanceCommon.get("Common/GetProgresSetupStatusDetail?",{ params });
  }
  async GetAllModule(params){
    return await http.instanceCommon.get("/Configuration/GetAllModule");
  }

   GetAllowFileType(){
    return http.instance.get("/CheckAllowFileType");
  }
  SaveNewRFIDTag(param) {
    return http.instance.post("/SaveNewRFIDTag/", param);
  }
  GetMeterListing(param){
    return http.instance.get("/GetMeterListing");
  }
}

export default new DataService();
