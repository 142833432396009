
<script>
    import { storeToRefs } from "pinia";  
    import { useloggedUserInfo,moduleStore} from "./stores/UserInfo";
    import SaveBookMark from "../src/components/BookMark/SaveBookMark.vue";
    export default {
      components: { SaveBookMark },
  data(){
   
    return{
      showAddBookMark:false,
       controllerName:null,
       actionName:null, 
    }
  },
  created(){ 
    var self = this;
    var pathName = window.location.pathname.split("/");
    self.controllerName= pathName[1].toLowerCase();
    self.actionName= pathName[2].toLowerCase();   
    window.addEventListener('event_addbookmark', (customEvent) => {      
      self.showAddBookMark = true;
    }); 
  },
    setup() {
        const store = useloggedUserInfo();
        const modulestore = moduleStore();
        const { GetUserInfo } = storeToRefs(store);
        const { GetModuleId } = storeToRefs(modulestore);
        return { GetUserInfo, GetModuleId };
    },
}
</script>
<template>
  <section>
    <ModuleHeader v-if="GetUserInfo != null" :modulemasterid="String(GetModuleId)"></ModuleHeader>   
    <section id="main-content" class="main-content">
            <div class="container-fluid">
                <div class="row" style="min-height:100vh;">
                    <div class="col-12 mt-2">
                      <router-view/>
                    </div>
                </div>
            </div>
            <tooltip  :width="250"/>
            <SaveBookMark v-if="showAddBookMark" v-on:closebookmark="showAddBookMark=false"></SaveBookMark>
        </section>
  </section>       
</template>
<style>
@media (min-width: 1024px) {
  body {
    display: flex;
    place-items: center;
  }
}
a{text-decoration:none;}

/* .switch.dynamic-form .slider.round.toggle-no span {    
  left: 0px !important;
}
input:checked + .slider {
    text-indent: -13px !important;
} */

</style>
